import React from "react";
import { RedirectModal } from "@fanduel/og-fe-library";

import deviceType from "@/events/utils/deviceType";
import {
  ANDROID_STORE_SPORTSBOOK_URL,
  APPLE_STORE_SPORTSBOOK_URL,
} from "@/events/utils/constants";

export type RedirectToSportsbookProps = {
  isOpen: boolean;
  sportsbookRedirectUrl: string;
  onRequestClose: () => void;
  onClickPrimaryCta: () => void;
  isInAppRouter?: boolean;
};

const RedirectToSportsbook: React.FC<RedirectToSportsbookProps> = ({
  isOpen,
  sportsbookRedirectUrl,
  onRequestClose,
  onClickPrimaryCta,
  isInAppRouter,
}: RedirectToSportsbookProps) => {
  const isMobile = deviceType()?.isPhone;
  const { os } = deviceType();
  const storeUrl =
    os === "iOS" ? APPLE_STORE_SPORTSBOOK_URL : ANDROID_STORE_SPORTSBOOK_URL;
  return (
    <RedirectModal
      isOpen={isOpen}
      redirectUrl={sportsbookRedirectUrl}
      onRequestClose={onRequestClose}
      isMobile={isMobile}
      appStoreUrl={isMobile ? storeUrl : undefined}
      onClickPrimaryCta={onClickPrimaryCta}
      appElement={isInAppRouter ? "body" : undefined}
    />
  );
};

export default RedirectToSportsbook;
